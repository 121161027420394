import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/gN5HSyFKJkI">
    <SEO title="Parenting in the Digital Age - Marriage, Money, and Minions" />
  </Layout>
)

export default SermonPost
